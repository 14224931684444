<template>
  <div class="operate">
    <Button
      size="large"
      class="btn cancel"
      v-if="step === 0"
      @click="this.$parent.closePage"
    >
      取消
    </Button>
    <Button
      size="large"
      class="btn cancel"
      @click="this.$parent.back"
      v-if="step === 1"
    >
      返回修改信息
    </Button>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <Button
      size="large"
      class="btn submit"
      html-type="submit"
      type="primary"
      @click="this.$parent.information"
      v-if="step !== 2"
      :disabled="isNext"
    >
      {{ nextName }}
    </Button>
  </div>
</template>

<script>
import { Button } from 'ant-design-vue';

export default {
  name: 'InsurePageOperate',
  components: {
    Button,
  },
  props: {
    step: {
      type: Number,
      default: 0,
    },
    isNext: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.step === 0) {
      this.nextName = '下一步';
    } else if (this.step === 1) {
      this.nextName = '确认投保';
    }
  },
  data() {
    return {
      nextName: '下一步',
    };
  },
};
</script>

<style lang="less" scoped>
.operate{
  width: 100%;
  text-align: center;
  .btn{
    width: 200px;
    height: 48px;
    &.cancel{
      border-color:#40a9ff;
      color:#40a9ff
    }
    &.submit{
      margin-left:50px;
    }
  }
}
</style>
