var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step1"},[_c('PlateTitle',{attrs:{"title":"投保信息"}}),_c('div',{staticClass:"tips-message"},[_c('a-alert',{attrs:{"message":"1.请提前一天购买保函，开标当天购买可能造成保函无效!","type":"warning","show-icon":""}}),_c('a-alert',{attrs:{"message":"2.请如实填写基本户信息，基本户信息错误导致的保函失效将由投保企业自行承担!","type":"warning","show-icon":""}})],1),_c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('a-form',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 8 }}},[_c('a-form-item',{attrs:{"label":"申请人","has-feedback":""}},[_c('span',[_vm._v(_vm._s(_vm.dataSource.name))])]),_c('a-form-item',{attrs:{"label":"申请人统一社会信用代码","has-feedback":""}},[_c('span',[_vm._v(_vm._s(_vm.dataSource.creditCode))])])],1),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 8 }}},[_c('a-form-item',{attrs:{"label":"基本户户名（企业名称）","has-feedback":""}},[(_vm.step === 0)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'basicAccountName',
            { rules: [{ required: true, message: '请输入' }] } ]),expression:"[\n            'basicAccountName',\n            { rules: [{ required: true, message: '请输入' }] },\n          ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入基本户户名"}}):_vm._e(),(_vm.step === 1)?_c('span',[_vm._v(_vm._s(_vm.dataSource.basicAccountName))]):_vm._e()],1),_c('a-form-item',{attrs:{"label":"基本户账号","has-feedback":""}},[(_vm.step === 0)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'basicAccountNo',
            { rules: [{ required: true, message: '请输入'},{message: '请输入正确的基本户账号', validator:_vm.filterNonNumeric}] } ]),expression:"[\n            'basicAccountNo',\n            { rules: [{ required: true, message: '请输入'},{message: '请输入正确的基本户账号', validator:filterNonNumeric}] },\n          ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入基本户账号"}}):_vm._e(),(_vm.step === 1)?_c('span',[_vm._v(_vm._s(_vm.dataSource.basicAccountNo))]):_vm._e()],1),_c('a-form-item',{attrs:{"label":"经办人手机号","has-feedback":""}},[(_vm.step === 0)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'phoneNum',
            { rules: [{ validator: _vm.compareToFirstPhone }] } ]),expression:"[\n            'phoneNum',\n            { rules: [{ validator: compareToFirstPhone }] },\n          ]"}],staticStyle:{"width":"280px"},attrs:{"placeholder":"请输入经办人手机号"}}):_vm._e(),(_vm.step === 1)?_c('span',[_vm._v(_vm._s(_vm.dataSource.mobile))]):_vm._e()],1),(_vm.step === 1)?_c('span',[_c('a-form-item',{attrs:{"label":"开立人","has-feedback":""}},[_c('span',[_vm._v(_vm._s(_vm.dataSource.openPeople))])]),_c('a-form-item',{attrs:{"label":"担保金额","has-feedback":""}},[_c('span',[_vm._v(_vm._s(_vm.dataSource.sumAmount)+"元")])]),_c('a-form-item',{attrs:{"label":"担保费","has-feedback":""}},[_c('span',[_vm._v(_vm._s(_vm.dataSource.sumPremium)+"元")])]),_c('a-form-item',{attrs:{"label":"费率","has-feedback":""}},[_c('span',[_vm._v(_vm._s(_vm.dataSource.rate)+"%")])])],1):_vm._e(),(_vm.step === 0)?_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'checked',
            { rules: [{ validator: _vm.compareToFirstChecked }] } ]),expression:"[\n            'checked',\n            { rules: [{ validator: compareToFirstChecked }] },\n          ]"}],staticStyle:{"width":"500px"}},[_vm._v(" 已阅读并同意"),_c('a',{staticClass:"hrefa",attrs:{"href":'/api/insure/index/download?type=apply&orderNo=' + _vm.orderNo},on:{"click":function($event){return _vm.download(1)}}}),_c('a',{staticClass:"hrefa",attrs:{"href":'/api/insure/index/download?type=rules&orderNo=' + _vm.orderNo},on:{"click":function($event){return _vm.download(2)}}},[_vm._v("《投标保函开立、退费、代偿规则》")]),_c('a',{staticClass:"hrefa",attrs:{"href":'/api/insure/index/download?type=introduction&orderNo=' +
              _vm.orderNo},on:{"click":function($event){return _vm.download(3)}}},[_vm._v("《投标保证金电子保函业务介绍》")])])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }